import { Component, Input, OnInit,ViewEncapsulation } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InputSwitchModule } from 'primeng/inputswitch';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { DividerModule } from 'primeng/divider';
import { FormsModule } from '@angular/forms';
import {RouterModule} from '@angular/router';
import { CommonModule } from '@angular/common';
import { StorageService } from '../../services/storage.service';
import { EmittersService } from '../../services/emitters.service';
@Component({
  selector: 'app-heading',
  standalone: true,
  imports: [InputSwitchModule,ButtonModule, FormsModule, RouterModule, BreadcrumbModule, DividerModule, CommonModule],
  templateUrl: './heading.component.html',
  styleUrl: './heading.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class HeadingComponent implements OnInit {
  items: any;
  @Input() title?: string = '';
  @Input() showToggle?: boolean = false;
  @Input() isbreadcrumb?: boolean = true;
  @Input() isReviewer?: boolean = false;
  @Input() button?: any;
  @Input() extraButton?: any;
  checked: boolean = false;
  showCurrentUserProjects = true;

  constructor(
    private storageService: StorageService,
    private emitterService: EmittersService
  ) { }

  ngOnInit(): void {

    if(this.button && !this.button.icon){
      this.button.icon = 'pi pi-plus';
    }

    // this.showCurrentUserProjects = mpCookies.get('showCurrentUserProjects') === 'true' ? true : false;
    // mpCookies.set('showCurrentUserProjects',  this.showCurrentUserProjects);


    this.items = [
      { label: 'Project' }, 
      { label: this.title?.split(' ')[0] }, 
      { label: 'Details' }, 
    ];
    this.initializeState();
  }

  initializeState(){
    const showCurrentUserProjects = this.storageService.getCookieValueByKey('showCurrentUserProjects'); 
    this.showCurrentUserProjects = !!(showCurrentUserProjects && showCurrentUserProjects === 'true');
  }

  onMyProjectToggleSwitch() {
    console.log(this.showCurrentUserProjects);
    this.storageService.setCookieValue('showCurrentUserProjects',  this.showCurrentUserProjects);
    this.emitterService.myProjectEmitter.emit(this.showCurrentUserProjects)
  }
}
